import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, Dropdown } from "react-bootstrap"
import { css } from "@emotion/core"

const ContactInfo = () => {
  const dropdownItemStyle = css`
    :active,
    :hover {
      background: none;
      color: black;
    }
  `
  return (
    <>
      <Dropdown.Item className='mt-2' as='span' css={dropdownItemStyle}>
        <b className='d-block'>Email Us</b>
        <span className='my-2'>
          <span className='d-block'>
            <a href='mailto:customerhelp@makenta.com.my' className='my-2'>
              customerhelp@makenta.com.my
            </a>
          </span>
        </span>
      </Dropdown.Item>
      <Dropdown.Item className='mt-2' as='span' css={dropdownItemStyle}>
        <b className='d-block'>Contact Info</b>
        <span className='my-2'>
          <span className='d-block'>
            <a href='tel:60378439806' className='my-2'>
              +60(3)-7843 9806
            </a>
            <br />
            <a href='tel:60378439877' className='my-2'>
              +60(3)-7843 9877
            </a>
          </span>
        </span>
      </Dropdown.Item>
      <Dropdown.Item className='mb-2' as='span' css={dropdownItemStyle}>
        <b className='d-block'>Our Location</b>
        <Link to='/location'>Click here to view map</Link>
      </Dropdown.Item>
      <Dropdown.Item as='span' className='px-2' css={dropdownItemStyle}>
        <Link className='btn btn-primary btn-block' to='/contact-us'>
          Message Us
        </Link>
      </Dropdown.Item>
    </>
  )
}

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      makentaLogo: file(
        relativePath: { eq: "makenta-long-primary-white.png" }
      ) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Navbar
      bg='dark'
      expand='lg'
      className='navbar-dark pb-3 pb-md-2'
      css={css`
        margin-bottom: -2px;
      `}
    >
      <div className='container'>
        <Navbar.Brand>
          <Link
            to='/'
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Img fixed={data.makentaLogo.childImageSharp.fixed} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto'>
            <Link
              className='mt-3 mt-lg-0 ml-3 nav-link text-white'
              to='/products#batteries'
            >
              Batteries
            </Link>

            <Link className='ml-3 nav-link text-white' to='/products#testers'>
              Testers
            </Link>

            <Link
              className='mb-3 mb-lg-0 ml-3 nav-link text-white'
              to='/products#chargers'
            >
              Chargers
            </Link>

            <Dropdown
              alignRight
              className='ml-md-3 d-none d-md-none d-lg-block'
            >
              <Dropdown.Toggle variant='outline-primary' id='dropdown-basic'>
                Get In Touch
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <ContactInfo />
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown.Menu show className='d-block d-lg-none'>
              <ContactInfo />
            </Dropdown.Menu>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
