import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      makentaLogo: file(relativePath: { eq: "makenta-long-white-white.png" }) {
        childImageSharp {
          fixed(width: 190, height: 49) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <div
      css={css`
        background: #2b2b2b;
      `}
    >
      <Container className='text-white py-5'>
        <Row>
          <Col md='3' className='mb-3'>
            <Link
              to='/'
              css={css`
                display: flex;
                align-items: center;
                margin-bottom: 1rem;
              `}
            >
              <Img fixed={data.makentaLogo.childImageSharp.fixed} />
            </Link>
            <small className='d-block'>
              Copyright &copy; {new Date().getFullYear()} Makenta Engineering
              Sdn Bhd.
              <br /> All rights reserved
            </small>
          </Col>
          <Col md='2' className='mb-3'>
            <b className='d-block mb-2'>Products</b>
            <Link to='/products#batteries' className='d-block mb-2'>
              Batteries
            </Link>
            <Link to='/products#testers' className='d-block mb-2'>
              Testers
            </Link>
            <Link to='/products#chargers' className='d-block mb-2'>
              Chargers
            </Link>
          </Col>

          <Col md='2' className='mb-3'>
            <b className='d-block mb-2'>Company</b>
            <Link to='/location' className='d-block mb-2'>
              Our Location
            </Link>
            <Link to='/contact-us' className='d-block mb-2'>
              Contact Us
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
